import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Heading,
  Box,
  Container,
  Button,
  Spinner,
  useToast,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  List,
  ListItem,
  ListIcon,
  Progress,
  VStack,
  HStack,
  Divider,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Badge,
  CircularProgress,
  CircularProgressLabel,
  Card,
  CardHeader,
  CardBody,
  Icon,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { CheckIcon, CloseIcon, RepeatIcon, TimeIcon, StarIcon } from '@chakra-ui/icons';
import config from '../config';

const { API_BASE_URL } = config;

const SimulationResult = () => {
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const toast = useToast();
  const [retryCount, setRetryCount] = useState(0);

  const fetchFeedback = useCallback(async () => {
    if (!sessionId) {
      setError('Session ID not found. Unable to fetch feedback.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`${API_BASE_URL}/api/coach_feedback/${sessionId}`);
      if (response.data.feedback && Object.keys(response.data.feedback).length > 0) {
        setFeedback(response.data.feedback);
      } else {
        setError('Received empty feedback from the server.');
      }
    } catch (err) {
      console.error('Error fetching feedback:', err);
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setError(`Server error: ${err.response.status} - ${err.response.data.detail || err.response.data}`);
      } else if (err.request) {
        // The request was made but no response was received
        setError('No response received from the server. Please check your connection.');
      } else {
        // Something happened in setting up the request that triggered an Error
        setError(`Error setting up the request: ${err.message}`);
      }
      toast({
        title: "Error",
        description: `Failed to fetch feedback. ${err.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [sessionId, toast]);

  const renderGenericFeedback = () => (
    <Alert
      status="warning"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        Feedback Unavailable
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        {feedback.message}
      </AlertDescription>
    </Alert>
  );

  const getCriticalStatus = (criteria) => {
    // Check if the criteria string includes "critical" (case insensitive)
    return criteria.toLowerCase().includes("; critical");
  };
  
  const renderPerformanceAndSummary = () => (
    <Card>
      <CardHeader bg="blue.50" borderTopRadius="lg">
        <Heading size="md">Performance Summary</Heading>
      </CardHeader>
      <CardBody>
        <Grid templateColumns="1fr auto" gap={6}>
          {/* Summary Section */}
          <GridItem>
          <VStack align="start" spacing={2}>
            <Text>{feedback.summary || 'No summary available'}</Text>
            {feedback.no_pass && (
              <Text fontSize="sm" color="red.600">
                Some critical requirements were not met. Critical items are essential points that must be addressed to pass the evaluation.
              </Text>
            )}
          </VStack>
          </GridItem>
  
          {/* Score and Pass/No Pass Section */}
          <GridItem>
            <VStack spacing={4} minW="200px">
              {/* Score Circle */}
              <CircularProgress
                value={(feedback.overall_rating || 0) * 10}
                size="100px"
                thickness="8px"
                color={feedback.overall_rating >= 7 ? "green.400" : feedback.overall_rating >= 5 ? "orange.400" : "red.400"}
              >
                <CircularProgressLabel>
                  <Text fontSize="xl" fontWeight="bold">{feedback.overall_rating}/10</Text>
                </CircularProgressLabel>
              </CircularProgress>
  
              {/* Pass/No Pass Badge */}
              <Badge
                fontSize="md"
                colorScheme={!feedback.no_pass ? "green" : "red"}
                p={2}
                borderRadius="md"
              >
                {!feedback.no_pass ? "Pass" : "No Pass"}
              </Badge>
            </VStack>
          </GridItem>
        </Grid>
      </CardBody>
    </Card>
  );
  
  const renderDetailedFeedback = () => {

    const sectionsArray = Object.entries(feedback.sections || {}).map(([sectionName, items]) => ({
      section_name: sectionName,
      items: items
    }));

    return (
    <VStack spacing={6} align="stretch">
      {/* Performance and Summary */}
      {renderPerformanceAndSummary()}
        
        {/* Recommendations Card */}
        <Card>
          <CardHeader bg="teal.50" borderTopRadius="lg">
            <Heading size="md">Key Recommendations</Heading>
          </CardHeader>
          <CardBody>
          {Array.isArray(feedback.recommendations) && feedback.recommendations.length > 0 ? (
              <List spacing={3}>
                {feedback.recommendations.map((rec, i) => (
                  <ListItem key={i} display="flex" alignItems="flex-start">
                    <Icon as={StarIcon} color="teal.500" mr={2} mt={1} />
                    <Text>{rec}</Text>
                  </ListItem>
                ))}
              </List>
          ) : (
            <Text>No recommendations available.</Text>
          )}
          </CardBody>
        </Card>
        
        {/* Detailed Sections */}
        <Card>
          <CardHeader bg="blue.50" borderTopRadius="lg">
            <Heading size="md">Detailed Evaluation</Heading>
          </CardHeader>
          <CardBody p={0}>
      <Tabs>
              <TabList px={4} pt={4}>
          {sectionsArray.map((section, index) => (
                  <Tab key={index} _selected={{ color: 'blue.500', borderColor: 'blue.500' }}>
                    {section.section_name}
                  </Tab>
          ))}
        </TabList>

        <TabPanels>
          {sectionsArray.map((section, sectionIndex) => (
            <TabPanel key={sectionIndex}>
                    <VStack align="stretch" spacing={4}>
                      <List spacing={4}>
                  {section.items.map(renderCriterion)}
                </List>
              </VStack>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
          </CardBody>
        </Card>
    </VStack>
  );
  };

  const renderCriterion = (item) => (
    <ListItem key={item.criteria}>
    <HStack align="center" spacing={4}>
        <Box
          minW="24px"
          h="24px"
          borderRadius="full"
          bg={item.met ? "green.100" : "red.100"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
        <ListIcon
          as={item.met ? CheckIcon : CloseIcon}
          color={item.met ? "green.500" : "red.500"}
            w={3.5}
            h={3.5}
          m={0}
        />
        </Box>
      <VStack align="start" spacing={1} width="100%">
        <HStack width="100%" justify="space-between" align="center">
          <Text fontWeight="bold">{item.criteria}</Text>
          {item.critical && (
            <Badge 
              colorScheme="red" 
              variant="subtle"
              fontSize="xs"
            >
              Critical
            </Badge>
          )}
        </HStack>
          <Text fontSize="sm" color="gray.600">{item.justification}</Text>
        </VStack>
      </HStack>
    </ListItem>
  );

  useEffect(() => {
    fetchFeedback();
  }, [fetchFeedback, retryCount]);

  const handleRetry = () => {
    setLoading(true);
    setError(null);
    setRetryCount(prevCount => prevCount + 1);
  };

  return (
    <Container maxW="container.xl">
      <Box my={4}>
        <Heading as="h1" size="xl" mb={6}>Simulation Result</Heading>
        {error ? (
          <Box bg="red.100" p={4} borderRadius="md" mb={6}>
            <Text color="red.600">{error}</Text>
            <Button leftIcon={<RepeatIcon />} onClick={handleRetry} mt={2}>
              Retry
            </Button>
          </Box>
        ) : loading ? (
          <Box textAlign="center" mt={4}>
            <Spinner size="xl" />
          </Box>
        ) : feedback ? (
          feedback.is_generic ? renderGenericFeedback() : renderDetailedFeedback()
        ) : (
          <Text color="gray.500">
            No feedback available at this time. Please try again later.
          </Text>
        )}
        <Button
          colorScheme="blue"
          onClick={() => navigate('/dashboard')}
          mt={6}
        >
          Go back to the dashboard
        </Button>
      </Box>
    </Container>
  );
};

export default SimulationResult;