import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  HStack,
  Input,
  Select,
  Button,
  Tag,
  Divider,
  Stack,
  RadioGroup,
  Radio,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useAuth } from '../contexts/AuthContext';
import { useSimulationMode } from '../contexts/SimulationModeContext';
import Layout from './Layout';
import config from '../config';

function ScenarioSelection() {
  const { user } = useAuth();
  const [scenarios, setScenarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const { setCurrentMode, SIMULATION_MODES } = useSimulationMode();
  const [selectedMode, setSelectedMode] = useState('PRACTICE');
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    console.log('Fetching scenarios from API...');
    setLoading(true);
    const url = `${config.API_BASE_URL}/api/list_scenarios${user?.sub ? `?user_id=${user.sub}` : ''}`;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Received scenarios:', data);
        setScenarios(data); // Store the fetched scenarios
        setLoading(false);
      })
      .catch((e) => {
        console.error('Error fetching scenarios:', e);
        setError(e.message);
        setLoading(false);
      });
  }, [user]);

  const handleStartScenario = () => {
    if (selectedScenario) {
      const mode = SIMULATION_MODES[selectedMode];
      if (!mode) {
        toast({
          title: 'Error',
          description: 'Please select a valid simulation mode',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      setCurrentMode(mode);
      navigate(`/simulation/${selectedScenario.id}`);
    } else {
      toast({
        title: 'Select a Scenario',
        description: 'Please select a scenario to start.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const filteredScenarios = scenarios.filter(
    (scenario) =>
      scenario.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (categoryFilter === '' || scenario.category === categoryFilter)
  );

  const categories = [...new Set(scenarios.map((s) => s.category))];

  if (loading) {
    return (
      <Layout>
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
        </Box>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Text color="red.500">Error: {error}</Text>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container maxW="container.xl">
        <Heading as="h2" size="xl" mb={6} bgGradient="linear(to-r, blue.400, purple.400)" bgClip="text" textAlign="center">
          Select a Scenario
        </Heading>

        <VStack spacing={6}>
          {/* Search and Filter */}
          <HStack width="100%" spacing={4}>
            <Input
              placeholder="Search scenarios..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="lg"
              variant="filled"
            />
            <Select
              placeholder="All Categories"
              value={categoryFilter}
              onChange={(e) => setCategoryFilter(e.target.value)}
              size="lg"
            >
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </Select>
          </HStack>

          {/* Scenario List */}
          <HStack spacing={4} align="start" width="100%">
            <Box flex={1}>
              <VStack spacing={4}>
                {filteredScenarios.map((scenario) => (
                  <Box
                    key={scenario.id}
                    p={4}
                    borderRadius="md"
                    bg={selectedScenario?.id === scenario.id ? 'blue.50' : 'white'}
                    border={selectedScenario?.id === scenario.id ? '2px solid blue.400' : '1px solid gray.200'}
                    boxShadow="md"
                    cursor="pointer"
                    onClick={() => setSelectedScenario(scenario)}
                    _hover={{ transform: 'scale(1.02)', boxShadow: 'lg' }}
                  >
                    <Heading as="h3" size="md">{scenario.name}</Heading>
                    <Text fontSize="sm" color="gray.600">{scenario.category || 'Uncategorized'}</Text>
                    <Divider my={2} />
                    {scenario.tags?.length > 0 && (
                      <HStack>
                        {scenario.tags.map((tag, index) => (
                          <Tag key={index} colorScheme="blue">
                            {tag}
                          </Tag>
                        ))}
                      </HStack>
                    )}
                  </Box>
                ))}
              </VStack>
            </Box>

            {/* Scenario Details */}
            <Box flex={1} p={6} borderRadius="md" bg="gray.50" boxShadow="lg">
              {selectedScenario ? (
                <VStack align="start" spacing={4}>
                  <Heading as="h3" size="lg" color="blue.500">
                    {selectedScenario.name}
                  </Heading>
                  <Text><strong>Category:</strong> {selectedScenario.category || 'Uncategorized'}</Text>
                  <Text><strong>Description:</strong> {selectedScenario.description}</Text>

                  <Box>
                    <Text><strong>Tags:</strong></Text>
                    {selectedScenario.tags?.map((tag, index) => (
                      <Tag key={index} colorScheme="blue" mr={2}>
                        {tag}
                      </Tag>
                    ))}
                  </Box>

                  <Divider />

                  {/* Mode Selection */}
                  <RadioGroup onChange={(value) => setSelectedMode(value)} value={selectedMode}>
                    <Stack spacing={4}>
                      <Radio value="PRACTICE">Practice Mode</Radio>
                      <Radio value="EXAM">Exam Mode</Radio>
                      <Radio value="DEMO">Demo Mode</Radio>
                    </Stack>
                  </RadioGroup>

                  <Button colorScheme="blue" size="lg" onClick={handleStartScenario}>
                    Start Scenario
                  </Button>
                </VStack>
              ) : (
                <Text>Select a scenario to view details</Text>
              )}
            </Box>
          </HStack>
        </VStack>
      </Container>
    </Layout>
  );
}

export default ScenarioSelection;
