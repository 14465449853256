import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ReactMarkdown from 'react-markdown';
import { FaPaperPlane, FaMicrophone, FaStop, FaInfoCircle, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useSimulationMode } from '../contexts/SimulationModeContext';
import config from '../config';
import {
  Text,
  Box,
  Input,
  Button,
  IconButton,
  VStack,
  HStack,
  Flex,
  useToast,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Switch,
  Tooltip,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  UnorderedList,
  ListItem,
  Progress,
  Image,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Checkbox,
  OrderedList,
} from '@chakra-ui/react';

const { WEBSOCKET_URL, API_BASE_URL } = config;

// Internal Components
const WelcomeOverlay = ({ isOpen, initialMessage, onStart, currentMode, showWelcomeTimer, welcomeTimeRemaining, hasStartedSimulation }) => (
  <Modal 
    isOpen={isOpen && !hasStartedSimulation} 
    onClose={() => {}} 
    isCentered 
    size="xl" 
    closeOnOverlayClick={false} 
    closeOnEsc={false}
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        Welcome to the Simulation
        {currentMode.id === 'exam' && showWelcomeTimer && (
          <Box mt={4}>
            <Text fontSize="lg" color="blue.500">
              Time until auto-start: {Math.floor(welcomeTimeRemaining / 60)}:
              {(welcomeTimeRemaining % 60).toString().padStart(2, '0')}
            </Text>
            <Progress 
              value={(welcomeTimeRemaining / 120) * 100} 
              colorScheme="blue" 
              size="sm" 
              borderRadius="full"
              mt={2}
            />
          </Box>
        )}
      </ModalHeader>
      <ModalBody>
        <VStack spacing={4}>
          <Text>{initialMessage}</Text>
          {currentMode.id === 'exam' && (
            <Box bg="yellow.100" p={4} borderRadius="md" width="100%">
              <Text fontWeight="bold">Exam Mode Rules:</Text>
              <UnorderedList mt={2}>
                <ListItem>You have 7 minutes to complete this simulation</ListItem>
                <ListItem>No hints or feedback will be provided during the exam</ListItem>
                <ListItem>The simulation will automatically end when time expires</ListItem>
                <ListItem>The exam will auto-start in 2 minutes if you don't start it manually</ListItem>
              </UnorderedList>
            </Box>
          )}
        </VStack>
      </ModalBody>
      <ModalFooter>
          <Button 
            colorScheme="blue" 
            onClick={onStart}
          >
          {currentMode.id === 'exam' ? 'Start Now' : 'Start Simulation'}
          </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

const Header = ({ scenarioId, currentMode, onOpenMenu, timeRemaining }) => {
  const menuBg = useColorModeValue('teal.500', 'teal.200');
  const menuColor = useColorModeValue('white', 'gray.800');
  
  return (
    <Flex 
      justify="space-between" 
      align="center" 
      p={4} 
      borderBottom="1px" 
      borderColor="gray.200"
    >
      <Box>
        <Text fontSize="2xl" fontWeight="bold" mb={2}>
          Medical Simulation {currentMode.id === 'exam' ? '- Exam Mode' : ''}
        </Text>
      </Box>
      <HStack spacing={4}>
        {currentMode.id === 'exam' && timeRemaining > 0 && (
          <Box
            bg={timeRemaining <= 60 ? "red.100" : "white"}
            p={4}
            borderRadius="md"
            boxShadow="sm"
          >
            <VStack spacing={1}>
              <Text fontWeight="bold">Time Remaining:</Text>
              <Text 
                fontSize="xl" 
                color={timeRemaining <= 60 ? "red.600" : "black"}
              >
                {Math.floor(timeRemaining / 60)}:
                {(timeRemaining % 60).toString().padStart(2, '0')}
              </Text>
            </VStack>
          </Box>
        )}
      <Tooltip label="Settings (Restart, Go Back)" placement="bottom-end">
        <Button
          onClick={onOpenMenu}
          size="md"
          bg={menuBg}
          color={menuColor}
          _hover={{ bg: useColorModeValue('teal.600', 'teal.300') }}
          boxShadow="md"
        >
          Menu
        </Button>
      </Tooltip>
      </HStack>
    </Flex>
  );
};

const PatientSection = ({ patientMessage, isPatientSpeaking, imageUrl }) => {
  const borderColor = useColorModeValue('green.400', 'green.300');
  const messageBg = useColorModeValue('white', 'gray.700');
  
  return (
    <VStack spacing={6} align="center">
      <Box position="relative">
        <Box
          borderRadius="full"
          borderWidth={isPatientSpeaking ? "3px" : "0px"}
          borderColor={borderColor}
          p={1}
        >
          <Image
            src={imageUrl || '/images/default_person.jpg'}
            borderRadius="full"
            boxSize="400px"
            objectFit="cover"
            crossOrigin="anonymous"
            onError={(e) => {
              console.error('Image load error:', e);
              // Try direct fallback
              e.target.src = '/images/default_person.jpg';
            }}
            onLoad={(e) => {
              console.log('Image loaded successfully:', e.target.src);
            }}
            alt="Patient" // Add alt text for better debugging
          />
        </Box>
      </Box>
      {patientMessage && (
        <Box 
          bg={messageBg} 
          p={4} 
          borderRadius="md" 
          w="100%" 
          maxW="600px"
          boxShadow="sm"
        >
          <Text><strong>Patient:</strong> {patientMessage}</Text>
        </Box>
      )}
    </VStack>
  );
};

const UserInputSection = ({ 
  transcribedText,
  isRecording,
  onStartRecording,
  onStopRecording,
  showTextInput,
  onTextSubmit,
  textInput,
  onTextInputChange,
  isDisabled,
  setShowTextInput,
  currentMode 
}) => {
  return (
    <VStack spacing={4} w="full" maxW="600px" mx="auto">
      {transcribedText && (
        <Box bg="blue.50" p={4} borderRadius="md" w="full" boxShadow="sm">
          <Text><strong>You:</strong> {transcribedText}</Text>
        </Box>
      )}
      
      {currentMode.features.showTextInput && (
        <HStack width="full" spacing={4}>
          <Switch 
            colorScheme="teal" 
            isChecked={showTextInput} 
            onChange={() => setShowTextInput(!showTextInput)}
          />
          <Text>{showTextInput ? 'Hide' : 'Show'} Text Input</Text>
        </HStack>
      )}
      
      {showTextInput && (
        <HStack as="form" onSubmit={onTextSubmit} w="full">
          <Input
            value={textInput}
            onChange={onTextInputChange}
            placeholder="Type your message... (say 'goodbye' to end)"
            isDisabled={isDisabled}
          />
          <IconButton
            icon={<FaPaperPlane />}
            onClick={onTextSubmit}
            isDisabled={isDisabled || !textInput}
            colorScheme="teal"
            aria-label="Send message"
          />
        </HStack>
      )}

      <Box position="relative">
        <Tooltip 
          label="To end the simulation, simply say 'goodbye' to the patient"
          placement="top"
          hasArrow
        >
          <Box 
            position="absolute"
            top="-8"
            right="-8"
            cursor="pointer"
          >
            <FaInfoCircle color="gray" />
          </Box>
        </Tooltip>

      <IconButton
        icon={isRecording ? <FaStop /> : <FaMicrophone />}
        colorScheme={isRecording ? "red" : "teal"}
        onClick={isRecording ? onStopRecording : onStartRecording}
        isDisabled={isDisabled}
        isRound
        size="lg"
        width="80px"
        height="80px"
        fontSize="2xl"
        boxShadow="lg"
        _hover={{ transform: 'scale(1.05)', boxShadow: 'xl' }}
        transition="all 0.2s"
        aria-label={isRecording ? "Stop recording" : "Start recording"}
      />
      </Box>
    </VStack>
  );
};

const SidePanel = ({ currentMode, sidebarContent }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const bg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  
  // Safely get the progress items
  const progressItems = sidebarContent?.progress || {};
  
  return (
    <Box 
      w={isExpanded ? "40%" : "400px"}
      bg={bg} 
      borderLeft="1px" 
      borderColor={borderColor}
      h="100%"
      overflowY="auto"
      transition="width 0.3s ease"
      position="relative"
    >
      {/* Expand/Collapse Button */}
      <IconButton
        icon={<Box as={isExpanded ? FaChevronRight : FaChevronLeft} />}
        position="sticky"
        left="-10px"
        top="50%"
        transform="translateY(-50%)"
        zIndex={2}
        onClick={() => setIsExpanded(!isExpanded)}
        aria-label={isExpanded ? "Collapse sidebar" : "Expand sidebar"}
        size="sm"
        boxShadow="md"
      />

      <Tabs>
        <TabList>
          <Tab>Resource</Tab>
          <Tab>Prescription</Tab>
          <Tab>Progress</Tab>
        </TabList>

        {/* Doc Resource Tab */}
        <TabPanels>
          <TabPanel>
            <VStack align="stretch" spacing={4}>
              <Text fontSize="lg" fontWeight="bold">Medication Reference</Text>
              <Box 
                p={4} 
                bg="gray.50" 
                borderRadius="md"
                maxH={isExpanded ? "none" : "calc(100vh - 200px)"}
                overflowY="auto"
              >
                <ReactMarkdown
                  components={{
                    // Custom styling for markdown elements
                    h1: ({node, ...props}) => <Text fontSize="2xl" fontWeight="bold" mb={4} {...props} />,
                    h2: ({node, ...props}) => <Text fontSize="xl" fontWeight="bold" mb={3} {...props} />,
                    h3: ({node, ...props}) => <Text fontSize="lg" fontWeight="bold" mb={2} {...props} />,
                    p: ({node, ...props}) => <Text mb={3} {...props} />,
                    ul: ({node, ...props}) => <UnorderedList mb={3} spacing={2} {...props} />,
                    ol: ({node, ...props}) => <OrderedList mb={3} spacing={2} {...props} />,
                    li: ({node, ...props}) => <ListItem {...props} />,
                  }}
                >
                  {sidebarContent?.resource || 'No medication information available.'}
                </ReactMarkdown>
              </Box>
            </VStack>
          </TabPanel>

          {/* Prescription Tab */}
          <TabPanel>
            <VStack align="stretch" spacing={4}>
              <Text fontSize="lg" fontWeight="bold">Prescription Guidelines</Text>
              <Box 
                p={4} 
                bg="gray.50" 
                borderRadius="md"
                maxH={isExpanded ? "none" : "calc(100vh - 200px)"}
                overflowY="auto"
              >
                <ReactMarkdown>
                  {sidebarContent?.prescription || 'No prescription guidelines available.'}
                </ReactMarkdown>
              </Box>
            </VStack>
          </TabPanel>

          {/* Progress Tab */}
          <TabPanel>
            <VStack align="stretch" spacing={4}>
              <Text fontSize="lg" fontWeight="bold">Consultation Progress</Text>
              <Box 
                p={4} 
                bg="gray.50" 
                borderRadius="md"
                maxH={isExpanded ? "none" : "calc(100vh - 200px)"}
                overflowY="auto"
              >
                <VStack align="stretch" spacing={3}>
                  {Object.entries(progressItems).map(([step, instruction]) => (
                    <Tooltip key={step} label={instruction} placement="left">
                      <Checkbox isDisabled={currentMode.id === 'exam'}>
                      {step}
                    </Checkbox>
                    </Tooltip>
                  ))}
                  {Object.keys(progressItems).length === 0 && (
                    <Text color="gray.500">No progress items available</Text>
                  )}
                </VStack>
              </Box>
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};


const SimulationInterface = () => {
  // =========================================
  // State Declarations
  // =========================================
  
  // Add ui state for coming from backend
  const [initialMessage, setInitialMessage] = useState('');
  const [patientImageUrl, setPatientImageUrl] = useState(null);
  const [sidebarContent, setSidebarContent] = useState({
    resource: '',
    prescription: '',
    progress: {}
  });
  
  // Core simulation state
  const [sessionId, setSessionId] = useState(() => uuidv4());
  const [messages, setMessages] = useState([]);
  const [feedback, setFeedback] = useState(null);
  
  // UI state
  const [input, setInput] = useState('');
  const [showTextInput, setShowTextInput] = useState(false);
  const [isOverlayOpen, setIsOverlayOpen] = useState(true);
  
  // Welcome overlay timer
  const [welcomeTimeRemaining, setWelcomeTimeRemaining] = useState(120);
  const [showWelcomeTimer, setShowWelcomeTimer] = useState(false);
  const [hasStartedSimulation, setHasStartedSimulation] = useState(false);


  // Audio state
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  
  // Loading and simulation status
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [isSimulationEnded, setIsSimulationEnded] = useState(false);
  const [isEndingSimulation, setIsEndingSimulation] = useState(false);

  // New state for handling the last message only
  const [currentPatientMessage, setCurrentPatientMessage] = useState('');
  const [transcribedText, setTranscribedText] = useState('');

  // =========================================
  // Hooks and Refs
  // =========================================
  
  // Simulation mode context
  const { 
    currentMode, 
    timeRemaining, 
    setTimeRemaining,
  } = useSimulationMode();
  
  // Refs
  const socketRef = useRef(null);
  const audioRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  
  // Dialog refs
  const cancelRefRestart = useRef();
  const cancelRefGoBack = useRef();
  
  // Custom hooks
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useAuth();
  const { scenarioId } = useParams();
  
  // UI hooks
  const {
    isOpen: isSettingsModalOpen,
    onOpen: onOpenSettingsModal,
    onClose: onCloseSettingsModal
  } = useDisclosure();
  
  const {
    isOpen: isRestartConfirmOpen,
    onOpen: onOpenRestartConfirm,
    onClose: onCloseRestartConfirm
  } = useDisclosure();
  
  const {
    isOpen: isGoBackConfirmOpen,
    onOpen: onOpenGoBackConfirm,
    onClose: onCloseGoBackConfirm
  } = useDisclosure();

  // =========================================
  // Setup Effects
  // =========================================
  
  // Initial setup effect
  useEffect(() => {
    if (scenarioId && sessionId) {
      setupSocket(sessionId);
      setupSimulation();
    } else {
      handleSetupError('Missing session or scenario information');
    }
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [scenarioId, sessionId]);


  // Audio handling effect
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const handlePlay = () => setIsAudioPlaying(true);
      const handleEnded = () => {
        setIsAudioPlaying(false);
        setIsWaitingForResponse(false);
      };
      const handlePause = () => setIsAudioPlaying(false);

      audio.addEventListener('play', handlePlay);
      audio.addEventListener('ended', handleEnded);
      audio.addEventListener('pause', handlePause);

      return () => {
        audio.removeEventListener('play', handlePlay);
        audio.removeEventListener('ended', handleEnded);
        audio.removeEventListener('pause', handlePause);
      };
    }
  }, []);

  // Exam mode welcome overlay timer
  useEffect(() => {
    if (isOverlayOpen && currentMode.id === 'exam' && !hasStartedSimulation) {
      setShowWelcomeTimer(true);
      const timer = setInterval(() => {
        setWelcomeTimeRemaining(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            handleStartButtonClick();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
    return () => {};
  }, [isOverlayOpen, currentMode.id, hasStartedSimulation]);

  // Exam mode timer countdown
  useEffect(() => {
    let timer;

    if (hasStartedSimulation && currentMode.id === 'exam' && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining(prevTime => {
          if (prevTime <= 1) {
            clearInterval(timer);
            handleNormalEnding();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [hasStartedSimulation, currentMode.id, timeRemaining]);


  // =========================================
  // Socket and Setup Functions
  // =========================================

  const setupSocket = (newSessionId) => {
    socketRef.current = io(WEBSOCKET_URL, {
      transports: ['websocket'],
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });

    socketRef.current.on('connect', () => console.log('Socket connected'));
    socketRef.current.on('new_message', handleNewMessage);
    socketRef.current.on('simulation_ended', handleNormalEnding);
    socketRef.current.on('new_audio_ready', (data) => playAudio(data.audioPath));
  };


  const setupSimulation = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/setup_simulation`, {
        scenarioId,
        sessionId,
        userId: user?.sub,
        mode: currentMode.id
      });
  
      const { initialMessage, imageUrl, sidebar } = response.data;
      
      setInitialMessage(initialMessage || '');
      setPatientImageUrl(imageUrl || '');
      setSidebarContent(sidebar || {
        resource: '',
        prescription: '',
        progress: {}
      });
      
      if (currentMode.id === 'exam') {
        setTimeRemaining(currentMode.features.examDuration);
      }
    } catch (error) {
      handleError('Failed to set up simulation', error);
      navigate('/scenarios');
    }
  };

  // =========================================
  // Message Handling Functions
  // =========================================

  const handleNewMessage = (message) => {
    if (!message.isUser) {
      setCurrentPatientMessage(message.content);
      setIsWaitingForResponse(false);
    }
  };

  const handleTextSubmit = async (e) => {
    e.preventDefault();
    if (input.trim() && !isWaitingForResponse && !isSimulationEnded) {
      const message = input.trim();
      setTranscribedText(message); 
      const userMessage = { 
        sender: 'You', 
        content: message, 
        isUser: true 
      };
      setMessages(prev => [...prev, userMessage]);
      await sendMessageToServer(message);
      setInput('');
    }
  };

  const sendMessageToServer = async (message, timestamp = null) => {
    setIsWaitingForResponse(true);
    try {
      await axios.post(`${API_BASE_URL}/api/send_message`, {
        sessionId,
        message,
        userId: user?.sub,
        timestamp,
        mode: currentMode.id
      });
    } catch (error) {
      handleError('Failed to send message', error);
      setIsWaitingForResponse(false);
    }
  };

  // =========================================
  // Audio Handling Functions
  // =========================================

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      const chunks = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/wav' });
        sendAudioToServer(blob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      handleError('Failed to start recording', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const sendAudioToServer = async (blob) => {
    setIsWaitingForResponse(true);
    const formData = new FormData();
    formData.append('audio', blob, 'audio.wav');
    formData.append('sessionId', sessionId);
    formData.append('userId', user?.sub);
    formData.append('mode', currentMode.id);

    try {
      const response = await axios.post(`${API_BASE_URL}/api/transcribe_audio`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      
      if (response.data.text?.trim()) {
        const text = response.data.text.trim();
        setTranscribedText(text);
        await sendMessageToServer(text, response.data.timestamp);
      } else {
        showToast('warning', 'No speech detected. Please try again.');
        setIsWaitingForResponse(false);
      }
    } catch (error) {
      handleError('Failed to process audio', error);
      setIsWaitingForResponse(false);
    }
  };

  const playAudio = (audioPath) => {
    if (audioRef.current) {
      const fileName = audioPath.split('/').pop();
      audioRef.current.src = `${API_BASE_URL}/api/audio/${fileName}`;
      audioRef.current.play().catch(e => console.error('Error playing audio:', e));
    }
  };

  // =========================================
  // Simulation Control Functions
  // =========================================

  const handleStartButtonClick = async () => {
    setShowWelcomeTimer(false);
    setWelcomeTimeRemaining(0);
    setIsOverlayOpen(false);
    setHasStartedSimulation(true);
  
    if (currentMode.id === 'exam') {
    setTimeRemaining(7 * 60);
    }
    
    try {
      await axios.post(`${API_BASE_URL}/api/start_simulation`, {
        sessionId,
        userId: user?.sub,
        mode: currentMode.id
      });
    } catch (error) {
      handleError('Failed to start simulation', error);
      navigate('/scenarios');
    }
  };

  const handleNormalEnding = async () => {
    setIsEndingSimulation(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/end_simulation_normal`, {
        sessionId,
        userId: user?.sub,
        scenarioId,
        mode: currentMode.id
      });
      setFeedback(response.data.feedback);
      setIsSimulationEnded(true);
    } catch (error) {
      handleError('Failed to end simulation', error);
    } finally {
      setIsEndingSimulation(false);
    }
  };

  const handleAnomalyEnding = async () => {
    if (isSimulationEnded) return;
    try {
      await axios.post(`${API_BASE_URL}/api/end_simulation_anomaly`, {
        sessionId,
        userId: user?.sub,
        scenarioId,
        mode: currentMode.id
      });
    } catch (error) {
      console.error('Error ending simulation (anomaly):', error);
    }
  };

  // =========================================
  // Navigation and UI Control Functions
  // =========================================

  const handleGoToResult = () => {
    if (isSimulationEnded && feedback) {
      navigate(`/simulation-result/${sessionId}`, { 
        state: { 
          feedback,
          mode: currentMode.id
        } 
      });
    }
  };

  const handleRestart = async () => {
    setMessages([]);
    setInput('');
    setIsSimulationEnded(false);
    setFeedback(null);
    setInitialMessage('');
    setSessionId(uuidv4());
    setIsOverlayOpen(true);
    setHasStartedSimulation(false);
    if (currentMode.id === 'exam') {
      setTimeRemaining(null);
      setWelcomeTimeRemaining(120);
      setShowWelcomeTimer(true);
    }
  };

  const handleGoBack = () => {
    navigate('/scenarios');
  };

  const confirmRestart = async () => {
    onCloseRestartConfirm();
    onCloseSettingsModal();
    await handleAnomalyEnding();
    await handleRestart();
  };

  const confirmGoBack = async () => {
    onCloseGoBackConfirm();
    onCloseSettingsModal();
    await handleAnomalyEnding();
    handleGoBack();
  };

  // =========================================
  // Helper Functions
  // =========================================

  const handleError = (message, error) => {
    console.error(`${message}:`, error);
    showToast('error', error.response?.data?.detail || `${message}. Please try again.`);
  };

  const handleSetupError = (message) => {
    console.error(message);
    showToast('error', message);
    navigate('/scenarios');
  };

  const showToast = (status, description) => {
    toast({
      title: status === 'error' ? 'Error' : 'Warning',
      description,
      status,
      duration: 5000,
      isClosable: true,
    });
  };

  // =========================================
  // Main Render
  // =========================================
  
  // Main render

  return (
    <Flex direction="column" h="100vh">
      <Header 
        currentMode={currentMode}
        onOpenMenu={onOpenSettingsModal}
        timeRemaining={timeRemaining}
      />
      
      <Flex flex={1} overflow="hidden">
        <Box flex={1} p={6} overflowY="auto">
          <VStack spacing={8} align="stretch">
            <PatientSection 
              patientMessage={currentPatientMessage}
              isPatientSpeaking={isAudioPlaying}
              imageUrl={patientImageUrl}
            />
            
            <UserInputSection 
              transcribedText={transcribedText}
              isRecording={isRecording}
              onStartRecording={startRecording}
              onStopRecording={stopRecording}
              showTextInput={showTextInput}
              onTextSubmit={handleTextSubmit}
              textInput={input}
              onTextInputChange={(e) => setInput(e.target.value)}
              isDisabled={
                isSimulationEnded || 
                (!isRecording && (isWaitingForResponse || isAudioPlaying)) ||
                (currentMode.id === 'exam' && timeRemaining <= 0)
              }
              setShowTextInput={setShowTextInput}
              currentMode={currentMode}
            />
          </VStack>
        </Box>
        
        <SidePanel 
         currentMode={currentMode}
         sidebarContent={sidebarContent}
        />
      </Flex>
{/* Keep existing modals and overlays */}
      <WelcomeOverlay 
        isOpen={isOverlayOpen}
        initialMessage={initialMessage}
        onStart={handleStartButtonClick}
        currentMode={currentMode}
        showWelcomeTimer={showWelcomeTimer}
        welcomeTimeRemaining={welcomeTimeRemaining}
        hasStartedSimulation={hasStartedSimulation}
      />

      {/* Settings Modal */}
      <Modal 
        isOpen={isSettingsModalOpen} 
        onClose={onCloseSettingsModal} 
        isCentered 
        size="md"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Simulation Menu</ModalHeader>
          <ModalBody>
            <VStack spacing={4}>
              <Button 
                colorScheme="red" 
                onClick={onOpenRestartConfirm} 
                width="100%"
              >
                Restart Simulation
              </Button>
              <Button 
                colorScheme="gray" 
                onClick={onOpenGoBackConfirm} 
                width="100%"
              >
                Go Back to Scenarios
              </Button>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseSettingsModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Restart Confirmation Dialog */}
      <AlertDialog
        isOpen={isRestartConfirmOpen}
        leastDestructiveRef={cancelRefRestart}
        onClose={onCloseRestartConfirm}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Restart Simulation
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to restart the simulation? This will end the current session.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRefRestart} onClick={onCloseRestartConfirm}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmRestart} ml={3}>
                Restart
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Go Back Confirmation Dialog */}
      <AlertDialog
        isOpen={isGoBackConfirmOpen}
        leastDestructiveRef={cancelRefGoBack}
        onClose={onCloseGoBackConfirm}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Go Back to Scenarios
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to go back to scenario selection? This will end the current simulation.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRefGoBack} onClick={onCloseGoBackConfirm}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmGoBack} ml={3}>
                Go Back
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Status Indicators */}
      {isEndingSimulation && (
        <Flex 
          position="fixed"
          bottom="20px"
          left="50%"
          transform="translateX(-50%)"
          bg="white"
          p={4}
          borderRadius="md"
          boxShadow="md"
          zIndex={1000}
        >
          <Spinner color="teal.500" />
          <Text ml={2}>Ending simulation...</Text>
        </Flex>
      )}

      {/* Result Navigation */}
      {isSimulationEnded && (
        <Flex 
          position="fixed"
          bottom="20px"
          left="50%"
          transform="translateX(-50%)"
          zIndex={1000}
        >
          <Button 
            colorScheme="teal"
            onClick={handleGoToResult}
            isDisabled={!feedback}
            boxShadow="md"
            _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
            transition="all 0.2s"
          >
            Go to Result
          </Button>
        </Flex>
      )}

      {/* Hidden Audio Player */}
      <audio ref={audioRef} style={{ display: 'none' }} />
    </Flex>
  );
};

export default SimulationInterface;