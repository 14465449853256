import React from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Icon,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const NavBar = () => {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();

  const handleNavigateToDashboard = () => {
    navigate('/dashboard');
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (err) {
      console.error('Error signing out:', err);
    }
  };

  const displayName = user?.display_name || 'User';

  return (
    <Box
      bgGradient="linear(to-r, blue.500, blue.700)"
      px={6}
      py={2}
      boxShadow="sm"
      position="sticky"
      top={0}
      zIndex={10}
    >
      <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
        {/* Brand Name */}
        <Text
          fontSize="2xl"
          fontFamily="Poppins, sans-serif"
          fontWeight="bold"
          color="white"
          cursor="pointer"
          onClick={handleNavigateToDashboard}
          _hover={{ color: 'teal.200', textDecoration: 'underline' }}
        >
          OsceCoach
        </Text>
        {/* User Info */}
        <Flex alignItems={'center'}>
          <Text
            color="gray.100"
            fontSize="lg"
            fontFamily="Inter, sans-serif"
            mr={4}
            fontWeight="medium"
          >
            Welcome, <span style={{ fontWeight: 'bold' }}>{displayName}</span>
          </Text>
          <Menu>
            <MenuButton
              as={Button}
              bg="transparent"
              _hover={{ bg: 'blue.600' }}
              _active={{ bg: 'blue.700' }}
              px={2}
              rounded="full"
              cursor="pointer"
              minW={0}
              rightIcon={<ChevronDownIcon color="white" />}
            >
              <Avatar size={'sm'} name={displayName} />
            </MenuButton>
            <MenuList
              bg="gray.50"
              borderRadius="lg"
              boxShadow="lg"
              mt={2}
              overflow="hidden"
              fontFamily="Roboto, sans-serif"
            >
              <MenuItem
                onClick={() => navigate('/profile')}
                icon={<Icon as={ChevronDownIcon} />}
                _hover={{ bg: 'teal.100', color: 'teal.800' }}
                fontWeight="medium"
              >
                Profile
              </MenuItem>
              <MenuItem
                onClick={handleSignOut}
                icon={<Icon as={ChevronDownIcon} />}
                _hover={{ bg: 'red.100', color: 'red.800' }}
                fontWeight="medium"
              >
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Box>
  );
};

export default NavBar;
