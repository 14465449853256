import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import config from '../config';
import {
  Box,
  VStack,
  Text,
  Input,
  Button,
  IconButton,
  Card,
  CardBody,
  Progress,
  Badge,
  useToast,
  Flex,
  Divider,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';

const { API_BASE_URL } = config;

const TrainingInterface = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [sessionId, setSessionId] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [userAnswer, setUserAnswer] = useState('');
  const [submittedAnswer, setSubmittedAnswer] = useState('');
  const [showingModelAnswer, setShowingModelAnswer] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [questionsAnswered, setQuestionsAnswered] = useState(0);
  const [batchSize, setBatchSize] = useState(10); // Set batch size to 10

  // Initialize session
  useEffect(() => {
    const initSession = async () => {
      try {
      // Generate session ID first
      const newSessionId = crypto.randomUUID();
      
        const response = await axios.post(`${API_BASE_URL}/api/training/start`, {
            userId: user.id,
          sessionId: newSessionId,
        });
        
      // Save session ID and question data after successful response
      setSessionId(newSessionId);
        setCurrentQuestion(response.data.question);
      } catch (error) {
        toast({
          title: 'Error starting session',
          description: error.response?.data?.detail || error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    initSession();
  }, [user.id, toast]);

  const handleShowAnswer = async () => {
    try {
      // Record "no attempt" in the backend
      await axios.post(`${API_BASE_URL}/api/training/record-no-attempt`, {
        sessionId,
        timestamp: new Date().toISOString(),
      });
      
      // Set feedback with only model answer
      setFeedback({
        assessment: 'no attempt',
        model_answer: currentQuestion.model_answer,
      });
      setShowingModelAnswer(true);
      setQuestionsAnswered((prev) => prev + 1);
      } catch (error) {
        toast({
          title: 'Error recording response',
          description: error.response?.data?.detail || error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };
      
  const getAssessmentColor = (assessment) => {
    switch (assessment) {
      case 'correct':
        return 'green';
      case 'partially correct':
        return 'yellow';
      case 'incorrect':
        return 'red';
      default:
        return 'gray';
    }
  };
  
  const getAssessmentText = (assessment) => {
    switch (assessment) {
      case 'correct':
        return 'Correct!';
      case 'partially correct':
        return 'Partially Correct - Good Understanding';
      case 'incorrect':
        return 'Incorrect';
      default:
        return 'Not Evaluated';
    }
};

  // Handle text submission
  const handleSubmit = async () => {
  if (!userAnswer.trim() || !sessionId) {
    toast({
      title: 'Error',
      description: !sessionId ? 'Session not initialized' : 'Please enter an answer',
      status: 'warning',
      duration: 3000,
      isClosable: true,
    });
    return;
  }
  
    setIsSubmitting(true);

    try {
      const response = await axios.post(`${API_BASE_URL}/api/training/submit`, {
        sessionId: sessionId,
        answer: userAnswer,
      });
      
      setSubmittedAnswer(userAnswer);
      setFeedback(response.data.feedback);
      setQuestionsAnswered((prev) => prev + 1);
      setUserAnswer('');
    } catch (error) {
      toast({
        title: 'Error submitting answer',
        description: error.response?.data?.detail || error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle moving to next question
  const handleNext = async () => {
  if (!sessionId) {
    toast({
      title: 'Error',
      description: 'Session not initialized',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
    return;
  }

    try {
      setFeedback(null);
      setShowingModelAnswer(false);
      
      const response = await axios.post(`${API_BASE_URL}/api/training/next`, {
        sessionId: sessionId,
      });
      
      // Check if training is completed
      if (response.data.completed) {
        toast({
          title: 'Training Completed!',
          description: 'You have completed all questions.',
          status: 'success',
          duration: 5000,
          isClosable: true,
      });
        navigate('/dashboard');
        return;
      }
      
      // Update question immediately if we have it
      if (response.data.question) {
      setCurrentQuestion(response.data.question);
      }
    } catch (error) {
      toast({
        title: 'Error getting next question',
        description: error.response?.data?.detail || error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Handle voice recording
  const toggleRecording = async () => {
  if (!sessionId) {
    toast({
      title: 'Error',
      description: 'Session not initialized',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
    return;
  }

    if (isRecording) {
      // Stop recording
      if (audioRecorder) {
        audioRecorder.stop();
        setIsRecording(false);
      }
    } else {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const recorder = new MediaRecorder(stream);
        setAudioRecorder(recorder);

        recorder.ondataavailable = async (e) => {
          const formData = new FormData();
          formData.append('audio', new Blob([e.data], { type: 'audio/webm' }));
          formData.append('sessionId', sessionId);
          formData.append('timestamp', new Date().toISOString());

          try {
            const response = await axios.post(`${API_BASE_URL}/api/training/submit-voice`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
            });
            
            setFeedback(response.data.feedback);
            setQuestionsAnswered((prev) => prev + 1);
          } catch (error) {
            toast({
              title: 'Error submitting voice answer',
              description: error.response?.data?.detail || error.message,
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
        };

        recorder.start();
        setIsRecording(true);
      } catch (error) {
        toast({
          title: 'Error accessing microphone',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  // Colors and styles
  const bgGradient = useColorModeValue(
    'linear(to-r, teal.50, blue.50)',
    'linear(to-r, teal.900, blue.900)'
  );
  const cardBg = useColorModeValue('white', 'gray.800');
  const inputBg = useColorModeValue('gray.100', 'gray.700');

  return (
    <Box
      minH="100vh"
      w="100%"
      bgGradient={bgGradient}
      py={10}
      px={4}
    >
      <Box
        p={6}
        maxW="800px"
        mx="auto"
        bg={cardBg}
        boxShadow="xl"
        borderRadius="xl"
        transition="all 0.3s ease-in-out"
      >
        <Progress value={(questionsAnswered / batchSize) * 100} mb={6} borderRadius="md" />
        <Text textAlign="right" mb={2} fontWeight="medium" color="gray.600">
        Question {questionsAnswered + 1}/{batchSize}
      </Text>

      {/* Question Card */}
        <Card mb={4} boxShadow="md" borderRadius="lg">
        <CardBody>
            <Text fontSize="xl" fontWeight="bold" color="gray.700">
            {currentQuestion?.text || 'Loading...'}
          </Text>
        </CardBody>
      </Card>

      {/* Input Section */}
      {!feedback && (
        <VStack spacing={4}>
          <Flex w="100%">
            <Input
              value={userAnswer}
              onChange={(e) => setUserAnswer(e.target.value)}
              placeholder="Type your answer..."
              mr={2}
                bg={inputBg}
                _focus={{
                  borderColor: 'teal.400',
                  boxShadow: '0 0 0 1px teal.400',
                }}
                transition="all 0.2s"
            />
            <IconButton
              icon={isRecording ? <FaMicrophoneSlash /> : <FaMicrophone />}
              colorScheme={isRecording ? 'red' : 'blue'}
              onClick={toggleRecording}
              aria-label="Voice input"
                _hover={{ transform: 'scale(1.05)', boxShadow: 'md' }}
                transition="all 0.2s"
            />
          </Flex>
          <Flex w="100%" gap={4}>
          <Button
            colorScheme="blue"
            isLoading={isSubmitting}
            onClick={handleSubmit}
              flex="1"
                _hover={{ bg: 'blue.600', boxShadow: 'md' }}
                transition="all 0.2s"
          >
            Submit Answer
          </Button>
            <Button
              colorScheme="gray"
              onClick={handleShowAnswer}
              flex="1"
                _hover={{ bg: 'gray.300', boxShadow: 'md' }}
                transition="all 0.2s"
            >
              Show Answer
            </Button>
          </Flex>
        </VStack>
      )}

      {/* Feedback Section */}
      {feedback && (
          <VStack spacing={4} align="stretch" mt={6}>
            <Card boxShadow="md" borderRadius="lg">
            <CardBody>
              <VStack align="stretch" spacing={4}>
                {!showingModelAnswer && (
                  <>
                <Flex align="center" justify="center" mb={2}>
                  <Badge
                        variant="solid"
                    colorScheme={getAssessmentColor(feedback.assessment)}
                        fontSize="md"
                    px={4}
                    py={2}
                    borderRadius="full"
                  >
                    {getAssessmentText(feedback.assessment)}
                  </Badge>
                </Flex>

                {/* Your Answer */}
                <Box>
                  <Text fontWeight="medium" color="gray.600" mb={1}>
                    Your Answer:
                  </Text>
                  <Text p={3} bg="gray.50" borderRadius="md">
                        {submittedAnswer}
                  </Text>
                </Box>
              
              <Divider />
              
                    {feedback.detailed_feedback && (
                      <>
              <Box>
                      <Text fontWeight="medium" color="gray.600" mb={1}>
                        Feedback:
                      </Text>
                  <Text p={3} bg="blue.50" borderRadius="md">
                        {feedback.detailed_feedback}
                  </Text>
              </Box>

                <Divider />
                      </>
                    )}
                  </>
                )}
              
                {/* Model Answer (shown in both cases) */}
              <Box>
                  <Text fontWeight="medium" color="gray.600" mb={1}>
                    Model Answer:
                  </Text>
                  <Text p={3} bg="green.50" borderRadius="md">
                    {showingModelAnswer ? currentQuestion.model_answer : feedback.model_answer}
                  </Text>
              </Box>

                <Button 
                  colorScheme="green" 
                  onClick={handleNext}
                  size="lg"
                  mt={2}
                    _hover={{ bg: 'green.600', boxShadow: 'md' }}
                    transition="all 0.2s"
                >
                Next Question
              </Button>
            </VStack>
          </CardBody>
        </Card>
        </VStack>
      )}
    </Box>
    </Box>
  );
};

export default TrainingInterface;