import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  SimpleGrid,
  VStack,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react';
import { FaBook, FaUserMd, FaChartLine, FaBrain } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  // Primary and Secondary Features
  const primarySections = [
    {
      title: 'Start New Simulation',
      description: 'Practice real-life scenarios to enhance your communication skills.',
      action: 'Choose Scenario',
      path: '/scenarios',
      icon: FaUserMd,
    },
    {
      title: 'Training',
      description: 'Engage in mini-simulations and sharpen your skills.',
      action: 'Start Training',
      path: '/training',
      icon: FaBrain,
    },
  ];

  const secondarySections = [
    {
      title: 'My Progress',
      description: 'Track your performance and measure your improvement over time.',
      action: 'View Progress',
      path: '/progress',
      icon: FaChartLine,
    },
    {
      title: 'Study Resources',
      description: 'Access curated materials to support your learning journey.',
      action: 'View Resources',
      path: '/resources',
      icon: FaBook,
    },
  ];

  return (
    <Box bg="gray.50" minHeight="100vh" py={8}>
      <Container maxW="container.xl">
        {/* Primary Buttons */}
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} mb={12}>
          {primarySections.map((section, index) => (
            <VStack
              key={index}
              bg="white"
              boxShadow="lg"
              borderRadius="lg"
              overflow="hidden"
              align="center"
              p={8}
              transition="all 0.3s"
              _hover={{ transform: 'scale(1.05)', boxShadow: 'xl' }}
            >
              <Flex
                w={20}
                h={20}
                bg="teal.100"
                borderRadius="full"
                align="center"
                justify="center"
                mb={6}
              >
                <Icon as={section.icon} boxSize={10} color="teal.500" />
              </Flex>
              <Text fontSize="2xl" fontWeight="bold" textAlign="center">
                {section.title}
              </Text>
              <Text fontSize="md" color="gray.600" textAlign="center" mb={4}>
                {section.description}
              </Text>
              <Button
                colorScheme="teal"
                variant="solid"
                size="lg"
                onClick={() => navigate(section.path)}
              >
                {section.action}
              </Button>
            </VStack>
          ))}
        </SimpleGrid>

        {/* Secondary Buttons */}
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
          {secondarySections.map((section, index) => (
            <VStack
              key={index}
              bg="white"
              boxShadow="md"
              borderRadius="lg"
              overflow="hidden"
              align="center"
              p={6}
              transition="all 0.3s"
              _hover={{ transform: 'scale(1.03)', boxShadow: 'lg' }}
            >
              <Flex
                w={16}
                h={16}
                bg="blue.100"
                borderRadius="full"
                align="center"
                justify="center"
                mb={4}
              >
                <Icon as={section.icon} boxSize={8} color="blue.500" />
              </Flex>
              <Text fontSize="xl" fontWeight="semibold" textAlign="center">
                {section.title}
              </Text>
              <Text fontSize="sm" color="gray.600" textAlign="center" mb={3}>
                {section.description}
              </Text>
              <Button
                colorScheme="blue"
                variant="outline"
                size="md"
                onClick={() => navigate(section.path)}
              >
                {section.action}
              </Button>
            </VStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default Dashboard;
