import React from 'react';
import {
  Container,
  VStack,
  Text,
  Box,
  Icon,
  Divider,
  Flex,
  HStack,
} from '@chakra-ui/react';
import { AtSignIcon, EmailIcon, InfoIcon } from '@chakra-ui/icons';
import { useAuth } from '../contexts/AuthContext';

const Profile = () => {
  const { user } = useAuth();

  return (
    <Box
      bgGradient="linear(to-r, blue.50, white)"
      minH="100vh"
      py={10}
      px={4}
    >
      <Container maxW="container.sm">
        <Box
          bg="white"
          borderRadius="lg"
          boxShadow="md"
          p={6}
          textAlign="center"
          overflow="hidden"
        >
          <Text fontSize="3xl" fontWeight="bold" mb={4} color="blue.500">
            Profile
          </Text>
          <Divider mb={6} />
          {user ? (
            <VStack spacing={4} align="start">
              <HStack>
                <InfoIcon color="blue.400" />
                <Text fontSize="lg" fontWeight="medium">
                  Display Name: <strong>{user.display_name || 'N/A'}</strong>
                </Text>
              </HStack>
              <HStack>
                <InfoIcon color="blue.400" />
                <Text fontSize="lg" fontWeight="medium">
                  First Name: <strong>{user.first_name || 'N/A'}</strong>
                </Text>
              </HStack>
              <HStack>
                <InfoIcon color="blue.400" />
                <Text fontSize="lg" fontWeight="medium">
                  Last Name: <strong>{user.last_name || 'N/A'}</strong>
                </Text>
              </HStack>
              <HStack>
                <EmailIcon color="blue.400" />
                <Text fontSize="lg" fontWeight="medium">
                  Email: <strong>{user.email}</strong>
                </Text>
              </HStack>
              <HStack>
                <AtSignIcon color="blue.400" />
                <Text fontSize="lg" fontWeight="medium">
                  Subscription Plan: <strong>{user.user_tier || 'Free'}</strong>
                </Text>
              </HStack>
            </VStack>
          ) : (
            <Text fontSize="lg" color="gray.500">
              Loading...
            </Text>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Profile;
